import { CAButton, Icons } from '@careacademy/ca-design-system'
import { defineComponent, type PropType } from 'vue'

import { on } from '@/javascript/vuejs/helpers/emitEventHandler'

import DataTable, {
	type ColumnData,
	type RowData,
	type TableData,
} from './DataTable'
import InputSearch from './InputSearch'
import LoadingIcon from './LoadingIcon'

const { SVGChevronRight } = Icons
/* eslint-disable @typescript-eslint/no-unused-vars */
const emits = {
	remove: (selectedData: RowData[]): boolean => true,
	select: (selectedData: RowData[]): boolean => true,
}
/* eslint-enable */

export default defineComponent({
	name: 'MoveDataBetweenDataTables',

	props: {
		...on(emits),

		buttonText: { default: '', type: String },
		cellDataArray: { required: true, type: Array as PropType<string[]> },
		columns: { required: true, type: Array as PropType<ColumnData[]> },
		data: { required: true, type: Array as PropType<any[]> },

		dataType: { default: '', type: String },
		filterText: { default: 'Filter', type: String },
		inputId: { required: true, type: String },
		isLoading: { default: false, type: Boolean },
		isStickyHeader: { default: true, type: Boolean },
		itemsPerPage: { default: 20, type: Number },
		rows: { required: true, type: Array as PropType<RowData[]> },
		selectedRows: { required: true, type: Array as PropType<RowData[]> },
		showCheckboxes: { default: true, type: Boolean },
		showPagination: { default: true, type: Boolean },
		sortable: { default: true, type: Boolean },
	},

	emits,

	data(): Data {
		return {
			searchInput: '',
			selectedAddIds: [],
			selectedData: {
				columns: this.columns,
				rows: [],
			},

			selectedRemoveIds: [],
		}
	},

	computed: {
		selectedTableData(): TableData {
			return {
				columns: this.columns,
				rows: this.selectedRows,
			}
		},

		tableData(): TableData {
			return {
				columns: this.columns,
				rows: this.rows.filter(row => !this.selectedRows.some(selectedRow => selectedRow.rowId === row.rowId)),
			}
		},
	},

	methods: {
		addSelected(): void {

			const newSelectedRows = this.rows.filter(row => this.selectedAddIds.includes(row.rowId))

			this.$emit('select', newSelectedRows)

			this.selectedAddIds = []

			this.setDomInputValueToSelectedDataIds()
		},

		addSelectedDataItems(ids: string[]): void {
			this.selectedAddIds = ids
		},

		removeSelected(): void {
			const removingSelectedRows = this.selectedRows.filter(row => this.selectedRemoveIds.includes(row.rowId))

			this.$emit('remove', removingSelectedRows)

			this.selectedRemoveIds = []

			this.setDomInputValueToSelectedDataIds()
		},

		removeSelectedDataItems(ids: string[]): void {
			this.selectedRemoveIds = ids
		},

		setDomInputValueToSelectedDataIds(): void {
			const inputElement = document.getElementById(this.inputId)! as HTMLSelectElement

			if (!inputElement) {
				console.warn(`Element with id ${this.inputId} not found.`)

				return
			}

			const optionsArray = Array.from(inputElement.options)
			const selectedIds = this.selectedRows.map(i => i.rowId)

			for (const option of optionsArray) {
				option.selected = selectedIds.includes(option.value)
			}
		},
	},

	render() {
		return (
			<div class="move-data-between-tables" data-test="move-data-between-tables">
				{this.isLoading ?
					<LoadingIcon />

					:

					<div>
						<div class="move-data-between-tables__filter-container">
							<InputSearch
								additionalClasses="input-line input-line--full-width"
								additionalInputClasses="custom-menu__search-input"
								dataTest="search-input"
								placeholderText={this.filterText}
								v-model={this.searchInput}
							/>
						</div>

						<div class="move-data-between-tables__container">
							<div class="move-data-between-tables__data-table">
								<p>{this.selectedAddIds.length} {this.dataType} selected</p>

								<DataTable
									data={this.tableData}
									dataTest="data-table-data"
									isStickyHeader={this.isStickyHeader}
									itemsPerPage={this.itemsPerPage}
									message={this.tableData.rows.length === 0 ? `No ${this.dataType}` : ''}
									searchTerm={this.searchInput}
									showCheckboxes={this.showCheckboxes}
									showPagination={this.showPagination}
									sortable={this.sortable}
									tableLabel={this.dataType}
									v-model={this.selectedAddIds}
									onSelected={this.addSelectedDataItems}
								/>
							</div>

							{this.tableData.rows.length > 0 &&
								<div class="move-data-between-tables__actions">
									<CAButton
										class="mb-2"
										dataTest="data-table-select-button"
										disabled={this.selectedAddIds.length === 0}
										size="compact"
										onClick={this.addSelected}
									>
										Select {this.buttonText}
										<template slot="right-icon"><SVGChevronRight /></template>
									</CAButton>

									<CAButton
										dataTest="data-table-remove-button"
										disabled={this.selectedRemoveIds.length === 0}
										icon="SVGChevronLeft"
										size="compact"
										onClick={this.removeSelected}
									>
										Remove {this.buttonText}
									</CAButton>
								</div>
							}

							{this.tableData.rows.length > 0 &&
								<div class="move-data-between-tables__data-table">
									<p class="move-data-between-tables__data-selected">
										{this.selectedData.rows.length > 0 ?
											<span>{this.selectedRemoveIds.length} {this.dataType} selected</span>
											:
											<span />
										}
									</p>

									<DataTable
										data={this.selectedTableData}
										dataTest="data-table-selected-data"
										isStickyHeader={this.isStickyHeader}
										itemsPerPage={this.itemsPerPage}
										message={this.selectedTableData.rows.length === 0 ? `No ${this.dataType} selected` : ''}
										showCheckboxes={this.showCheckboxes}
										showPagination={this.showPagination}
										sortable={this.sortable}
										tableLabel={`Selected ${this.dataType}`}
										onSelected={this.removeSelectedDataItems}
									/>
								</div>
							}
						</div>
					</div>
				}
			</div>
		)
	},
})

interface Data {
	searchInput: string
	selectedAddIds: string[]
	selectedData: TableData
	selectedRemoveIds: string[]
}
